import "./form.css";
import { useState } from "react";
import { styled } from '@mui/system';
import { List, ListItem, ListItemText, Button } from '@mui/material';

import ConnectC, { getEncData } from "../ConnectC/ConnectC.js";

import QrScanner from "./QrScanner";
import Modal from "./Modal";

export default function Form() {
  const [certId, setCertId] = useState(0);
  const [cert, setCert] = useState([]);
  const [qrScanner, setQrScanner] = useState(false);


  const CenteredListItemText = styled(ListItemText)({
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500
  });

  const [decData, setDecData] = useState(0);
  const [password, setPassword] = useState('');

  function handleSubmit(event) {
    event.preventDefault();
    //console.log(certId);

    localStorage.setItem('certId', certId);
    ConnectC(certId).then((r) => {
      //console.log(r);
      r[0].length !== 0 ? setCert(r) : alert("Certificate not found");
    });
    //Reset decrypted data
    setDecData([]);
  }

  return (
    <div className="form-wrapper">
      <h3>Verify Now</h3>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Property ID" name='certID' onChange={(e) => setCertId(e.target.value)} autoComplete='off' />
        <button>Submit</button>
      </form>
      <p><button variant="contained" type="submit" onClick={() => setQrScanner(true)}>Scan QR Code</button></p>
      <List style={{ display: cert.rawData ? "block" : "none" }}>
        {/* <ListItem divider><a>{"Property ID: " + cert.rawData}</a></ListItem> */}
        <ListItem divider><a>{"Issued On: " + new Date(cert.created * 1000).toLocaleString('en-GB')}</a></ListItem>
        {/* <ListItem divider><CenteredListItemText disableTypography primary={"Certificate is " + (Number(cert.state) === 0 ? "Pending" : Number(cert.state) === 1 ? "Valid" : Number(cert.state) === 2 ? "Declined" : '')} /></ListItem> */}
        <ListItem divider alignItems={"center"} style={{justifyContent: "center"}}>
          <button onClick={async () => {
            let tempPass = prompt("Enter decryption key")
            setPassword(tempPass);
            let decrypted = await getEncData(cert.encData, cert.photoHash, tempPass);
            setDecData(decrypted);
          }}>
            Open Additional Info
          </button>
        </ListItem>
        <Modal decData={decData} password={password} />
      </List>
      {qrScanner ? <QrScanner /> : ''}
    </div>
  );
}
