import React, { useState, useEffect } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Avatar from '@mui/material/Avatar';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CV from './CV'

import proj4 from 'proj4';

export default function Modal(props) {
    const [decOpen, setDecOpened] = useState(false);
    const [decFail, setDecFail] = useState(false);

    const decData = props.decData;
    const password = props.password;

    // const krgjsh = "+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs ";
    // const google = "+proj=longlat +datum=WGS84 +no_defs "; //WGS84
    // const googleCords = decData[8] ? proj4(krgjsh, google, decData[8].split(",").map(e => parseFloat(e))) : "";

    useEffect(() => {
        console.log(decData)
        if (decData.length === 8) /// UPDATE FOR EACH FIELD CHANGE, OTHERWISE THE MODAL WILL FAIL TO OPEN
            setDecOpened(true);

        if (decData instanceof Error)
            setDecFail(true);

    }, [decData])


    return (
        <div className="encData">
            <Dialog className="decData" open={decOpen} fullScreen onClose={() => { setDecOpened(false) }}>
                <DialogContent>
                    <Container>
                        <Avatar variant='rounded' style={{ width: 256, height: 256, margin: "0 auto" }} src={decData[7]} alt='Photo' />
                    </Container>
                    <TableContainer style={{ display: "flex" }}>
                        <Table size="small" style={{ flex: "50%" }}>
                            <TableBody>
                                    <TableRow>
                                        <TableCell variant="head">Parcel No.:</TableCell><TableCell>{decData[0]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Building No.</TableCell><TableCell>{decData[1]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Data from converted import:</TableCell><TableCell>{decData[2]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Apt/Floor/No.:</TableCell><TableCell>{decData[3]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Description:</TableCell><TableCell>{decData[4]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Area (m2):</TableCell><TableCell>{decData[5]}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell variant="head">Lat. Long. Coordinates:</TableCell><TableCell>{decData[6]}</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>

                        <div style={{ width: "100%" }}><iframe title={decData[3]} width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight={0} marginWidth={0} src={`https://maps.google.com/maps?width=100%25&height=100%25&hl=en&q=${decData[6]}+(${decData[3]})&t=h&z=16&ie=UTF8&iwloc=B&output=embed`}></iframe></div>
                    </TableContainer>
                    <Box textAlign="center">
                        <Button style={{ justifyContent: 'center', marginTop: '12px' }} variant="contained" type="submit" onClick={
                            () => {
                                CV(decData, password);
                            }
                        }>Download Report</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog open={decFail} onClose={() => { setDecFail(false) }}><p>Decryption Failed</p></Dialog>
        </div>
    );
}