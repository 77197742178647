import React, { useState } from 'react'
import QrReader from 'react-qr-reader'
import ConnectC, { getEncData } from "../ConnectC/ConnectC.js";
import Modal from "./Modal";
import Dialog from '@mui/material/Dialog';

export default function QrScanner() {
    const [qrOutput, setQrOutput] = useState(undefined);
    const [error, setError] = useState(false);

    async function handleScan(res) {
        if (res && !qrOutput) {
            let scanRes;
            try {
                scanRes = atob(res).split(';');
            } catch (err) {
                setError(true);
                alert("Scanning failed. Please reload and try again");
                return;
            };
            console.log(scanRes);
            ConnectC(scanRes[0]).then((r) => {
                //console.log(r);
                if (r[0].length !== 0) {
                    getEncData(r.encData, r.photoHash, scanRes[1]).then((res) => setQrOutput(res));
                    //console.log(qrOutput);
                } else {
                    alert("Certificate not found");
                }
            });
        };
        return ("TEXT");
    }

    const previewStyle = {
        height: 240,
        width: 320,
        margin: "0 auto",
    }

    const enabledQrReader = qrOutput === undefined ? <QrReader
        delay={100}
        resolution={1024}
        style={previewStyle}
        //onError={handleScan}
        onScan={handleScan} />
        : <Modal decData={qrOutput} />;
    return (
        <div>{error === false ? enabledQrReader : <Dialog><p>Scanning Failed</p><p>Please reload and try again</p></Dialog>}
        </div>
    );
}