import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
  palette: {
    type: "light",
    background: {
      default: "#c7c7c7",
    },
    primary: {
      main: "#0051f2",
    },
    secondary: {
      main: "#ff9800"
    }
  }
})

console.log(theme);
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <ThemeProvider theme={theme}>
  <StrictMode>
      <CssBaseline />
      <App />
  </StrictMode>
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
